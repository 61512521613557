<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-50 inset-0 overflow-scroll"
      @close="$emit('close')"
      :open="open"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:w-full
            "
            :class="{
              'sm:max-w-lg ': size === 'lg',
              'sm:max-w-xl ': size === 'xl',
              'sm:max-w-2xl ': size === '2xl',
              'sm:max-w-4xl ': size === '4xl',
            }"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  v-if="title"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  {{ title }}
                </DialogTitle>
                <div :class="{ 'mt-2': title }">
                  <slot />
                </div>
              </div>
            </div>
            <div
              v-if="footer"
              class="
                bg-gray-50
                px-4
                py-3
                sm:px-6
                flex flex-row-reverse
                justify-between
              "
            >
              <slot name="buttons" />
              <div>
                <Button @click="$emit('close')"> Cancel </Button>
                <slot name="buttonsMiddle" />
                <Button
                  class="sm:ml-3"
                  v-if="action"
                  :status="status"
                  :loading="loading"
                  @click="$emit('submit')"
                >
                  {{ action }}
                </Button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import Button from "@/components/common/Button";

export default {
  emits: ["close", "submit"],

  components: {
    Button,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    open: Boolean,
    loading: Boolean,
    title: String,
    action: String,
    footer: {
      type: Boolean,
      default: true,
    },
    status: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "4xl",
    },
  },
};
</script>
